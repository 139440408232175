import React, { useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import { Helmet } from 'react-helmet';

import { isAgent } from 'src/api/client';
import Checkmark from 'src/components/icons/checkmark.svg';
import ShelbyFooter from 'src/components/organism/ShelbyFooter/ShelbyFooter';
import useProfile from 'src/api/useProfile';
import { Translate } from 'src/components/Translate';
import { useTracking, useTrackingContext } from 'src/components/TrackingContext';

import styles from './membership-issued.module.scss';

const metadata = {
  title: 'Membership Issued | HDC for State Farm',
};

const MembershipIssued = () => {
  const [callOnce, setCallOnce] = useState(false);
  const { data: profile } = useProfile();
  const { trackView } = useTrackingContext();

  useTracking('member_complete', null, true);

  useEffect(() => {
    if (trackView && !callOnce) {
      trackView('member_complete', null, {
        virtual_page_name: 'membership_issued',
      });
      setCallOnce(true);
    }
  }, [trackView]);

  useEffect(() => {
    if (!isAgent) {
      navigate('/');
    }
  }, []);

  return (
    <>
      <Helmet title={metadata.title} />
      <div className="container container_center agent-membership-issued">
        <div className="agent-membership-issued__main">
          <div className="agent-membership-issued__message">
            <Checkmark />
            <Translate resourceKey={'membershipIssued.notification.checkmark'} />
          </div>
          <Translate as="h2" resourceKey={'membershipIssued.main.title'} />
          <Translate as="p" dynamicKeys={[profile?.givenName]} resourceKey={['membershipIssued.main.text']} />
          <div className="inset-l" />
          <Translate className="state-farm-disclaimer" resourceKey={'state-farm.disclaimer.footer'} as="span" />
        </div>

        <div className={styles.footer}>
          <ShelbyFooter showDisclaimer={false} />
        </div>
      </div>
    </>
  );
};

export default MembershipIssued;
